export default {
	otd: {
		title: 'OTD',
		desc: 'Herramientas para informar y gestionar los números de OTD.',
		routes: {
			e2ereport: {
				desc: 'Transmitir semanalmente los informes de rendimiento de E2E OTD.',
				title: 'Informe E2E',
			},
			e2ereportmgmt: {
				desc: 'Transmitir semanalmente los informes de rendimiento de E2E OTD.',
				title: 'Gestión de informes E2E',
			},
			e2eutilities: {
				desc: 'Transmitir semanalmente los informes de rendimiento de E2E OTD.',
				title: 'Servicios E2E',
			},
			rxreport: {
				desc: 'Transmitir semanalmente los informes de rendimiento de E2E OTD.',
				title: 'Informe RX',
			},
		},
	},
};
