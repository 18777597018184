export default {
	otd: {
		title: 'OTD',
		desc: 'Tools for reporting and managing OTD numbers.',
		routes: {
			e2ereport: {
				desc: 'Transmit weekly E2E OTD performance reports.',
				title: 'E2E Report',
			},
			e2ereportmgmt: {
				desc: 'Transmit weekly E2E OTD performance reports.',
				title: 'E2E Report Management',
			},
			e2eutilities: {
				desc: 'Transmit weekly E2E OTD performance reports.',
				title: 'E2E Utilities',
			},
			rxreport: {
				desc: 'Transmit weekly E2E OTD performance reports.',
				title: 'RX Report',
			},
		},
	},
};
