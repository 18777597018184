export default {
	otd: {
		title: 'OTD',
		desc: 'This area provides tools for reporting and managing OTD numbers.',
		routes: {
			e2ereport: {
				desc: 'Übermitteln Sie wöchentliche E2E OTD-Leistungsberichte.',
				title: 'E2E Report',
			},
			e2ereportmgmt: {
				desc: 'Verwalten Sie Hierarchien von E2E OTD-Leistungsberichten und deren Funktionen.',
				title: 'E2E Report Management',
			},
			e2eutilities: {
				desc: 'Dienstprogramme bezüglich E2E OTD-Reporting.',
				title: 'E2E Utilities',
			},
			rxreport: {
				desc: 'Senden Sie den wöchentlichen Betriebsberichts-Rx.',
				title: 'RX Report',
			},
		},
	},
};
