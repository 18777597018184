import { ROUTE_PATHS } from './routes-paths';
import { AUTH_GUARD } from '@zeiss/ng-vis-vp-auth/injection-tokens';
import { vpRule } from '@zeiss/ng-vis-vp-auth/types';
import { VpRoute } from '@zeiss/ng-vis-vp-auth/types';
import { RULE } from '@zeiss/ng-vis-vp-auth/constants';

export const ROUTE_CONFIG: VpRoute = {
	path: ROUTE_PATHS._,
	canActivate: [AUTH_GUARD],
	data: {
		title: 'external.otd.title',
		description: 'external.otd.desc',
		icon: 'zui-icon-time-time-time',
		showInMenu: true,
		rule: vpRule({
			rule: RULE.access_otd,
			required: [],
		}),
		routes: [
			{
				path: '',
			},
			{
				path: ROUTE_PATHS.e2ereport,
				canActivate: [AUTH_GUARD],
				data: {
					title: 'external.otd.routes.e2ereport.title',
					description: 'external.otd.routes.e2ereport.desc',
					icon: 'zui-icon-file-file-report-overview',
					showInMenu: true,
					cache: true,
					rule: vpRule({
						rule: RULE.access_otd,
						required: ['e2e/report'],
					}),
				},
			},
			{
				path: ROUTE_PATHS.e2ereportmgmt,
				canActivate: [AUTH_GUARD],
				data: {
					title: 'external.otd.routes.e2ereportmgmt.title',
					description: 'external.otd.routes.e2ereportmgmt.desc',
					icon: 'zui-icon-file-file-report-overview',
					showInMenu: true,
					cache: true,
					rule: vpRule({
						rule: RULE.access_otd,
						required: ['e2e/reportmgmt'],
					}),
				},
			},
			{
				path: ROUTE_PATHS.e2eutilities,
				canActivate: [AUTH_GUARD],
				data: {
					title: 'external.otd.routes.e2eutilities.title',
					description: 'external.otd.routes.e2eutilities.desc',
					icon: 'zui-icon-service',
					showInMenu: true,
					cache: true,
					rule: vpRule({
						rule: RULE.access_otd,
						required: ['e2e/utils'],
					}),
				},
			},
			{
				path: ROUTE_PATHS.rxreport,
				canActivate: [AUTH_GUARD],
				data: {
					title: 'external.otd.routes.rxreport.title',
					description: 'external.otd.routes.rxreport.desc',
					icon: 'zui-icon-size-distribution',
					showInMenu: true,
					cache: true,
					rule: vpRule({
						rule: RULE.access_otd,
						required: ['rx/report'],
					}),
				},
			},
		],
	},
};
